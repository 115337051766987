@import 'libs/vars';
@import 'libs/functions';
@import 'libs/mixins';
@import 'libs/vendor';
@import 'libs/breakpoints';
@import 'libs/html-grid';

/*
	Stellar by HTML5 UP
	html5up.net | @ajlkn
	Free for personal and commercial use under the CCA 3.0 license (html5up.net/license)
*/

/* Header */

	#header {
		body.is-preload & {
			&.alt {
				> * {
					opacity: 1;
				}

				.logo {
					@include vendor('transform', 'none');
				}
			}
		}
	}